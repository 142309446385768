import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled, { css } from "styled-components"
import * as v from "../variables"
import Breadcrumb from "./breadcrumb"
// import { motion, useViewportScroll, useTransform } from "framer-motion"

const Container = styled.div`
  grid-column: fullbleed;

  ${v.grid};

  /* &:before {
    content: "";
    display: block;
    grid-column: main;
  } */

  position: relative;

  h1,
  p {
    grid-column: main;
  }

  h1 {
    ${v.heading.b};
    color: ${v.purple};
    font-weight: bold;
    span {
      font-weight: 300;
      display: block;
    }

    @media all and (min-width: 768px) {
      ${v.heading.a}
      font-weight: bold;
    }
  }

  p {
    ${v.text.a};
    color: rgb(0 0 0 / 0.6);
  }
`

const Image = styled.div``

const TopWrapper = styled.div`
  grid-column: fullbleed;
  ${v.grid}
  position: relative;

  &:before {
    content: "";
    display: block;
    grid-column: full;
  }

  &.has-image {
    ${props =>
      props.color &&
      css`
        background-image: linear-gradient(
          to right,
          rgba(117, 89, 143, 1),
          rgba(31, 25, 50, 1)
        );
      `};

    .image,
    .color,
    .image:before,
    .image:after {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .color {
      background-image: linear-gradient(
        to left,
        rgba(117, 89, 143, 1),
        rgba(31, 25, 50, 1)
      );
      mix-blend-mode: hard-light;

      & + .image {
        mix-blend-mode: luminosity;

        &:before {
          content: "";
          background: ${v.darkPurple};
          mix-blend-mode: overlay;
          opacity: 0.5;
        }
      }
    }

    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(${props => props.image}) no-repeat center;
      background-size: cover;

      &:after {
        content: "";
        background-image: linear-gradient(
          to left,
          rgba(117, 89, 143, 1),
          rgba(31, 25, 50, 1)
        );
        mix-blend-mode: soft-light;
        opacity: 0.5;
      }
    }

    /* Space out header when there’s an image */
    &:after {
      display: block;
      content: "";
      grid-column: fullbleed;
      min-height: 33vh;
    }
  }

  .angle {
    grid-column: fullbleed;

    svg {
      height: 48px;
      width: 100%;
      position: absolute;
      bottom: 0;

      &:first-of-type {
        bottom: 1px;
      }
    }
  }
`

const Header = ({
  children,
  title,
  subtitle,
  description,
  breadcrumb,
  image,
  color,
  crumb,
}) => {
  // const { scrollY } = useViewportScroll()
  // const y = useTransform(scrollY, [0, 300], [0, 200])

  return (
    <Container>
      <TopWrapper image={image} color={color} className={image && "has-image"}>
        {breadcrumb ? (
          <Breadcrumb content={breadcrumb} pageTitle={crumb || title} />
        ) : (
          <p>&nbsp;</p>
        )}
        {image && (
          <>
            {color && <div className="color"></div>}
            <Image className="image"></Image>
            <div className="angle">
              <svg viewBox="0 0 100 100" preserveAspectRatio="none">
                <polygon fill="#ccc" points="0,0 0,100 100,100"></polygon>
              </svg>
              <svg viewBox="0 0 100 100" preserveAspectRatio="none">
                <polygon fill="white" points="0,0 0,100 100,100"></polygon>
              </svg>
            </div>
          </>
        )}
      </TopWrapper>
      {title && (
        <h1>
          {title}
          {subtitle && <span>{subtitle}</span>}
        </h1>
      )}

      {description && <p>{description}</p>}
      {children}
    </Container>
  )
}

export default Header
