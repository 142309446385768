import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import * as v from "../variables"

const Container = styled.ul`
  border-bottom: 1px solid rgba(0 0 0 / 0.2);
  grid-column: fullbleed;

  h2 {
    ${v.heading.c};
    color: ${v.purple};
    margin-bottom: ${v.baseline()};
    margin-left: ${v.baseline(2)};
  }

  @media all and (min-width: 600px) {
    grid-column: main;

    h2 {
      margin-left: 0;
    }

    li {
      border: 1px solid rgba(0 0 0 / 0.2);
      border-top: none;
      border-bottom: none;
    }
  }
`

const NavListItem = ({ children, title }) => (
  <Container>
    {title && <h2>{title}</h2>}
    {children}
  </Container>
)

export default NavListItem
