import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import styled from "styled-components"
import * as v from "../components/variables"

import Header from "../components/Header"
import NavList from "../components/NavList"
import NavListItem from "../components/NavList/item"

import LogoIcon from "../images/am-icon.png"

const Container = styled.div`
  ${v.grid}
`

const Logo = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  ${v.grid};

  img {
    grid-column: main;
    margin-top: ${v.baseline(2)};
    height: ${v.baseline(3)};
  }

  &:after {
    content: "";
    display: block;
    grid-column: full;
  }
`

const Calculator = styled.form`
  grid-column: main;

  display: grid;
  grid-template-columns: [full-start] 1fr 1fr [full-end];

  .result {
    grid-column: full;
    font-size: 24px;
    margin-bottom: ${v.baseline(2)};
  }
`

const IndexPage = ({ data }) => {
  const [SpO2, setSpO2] = useState(98)
  const [FiO2, setFiO2] = useState(96)
  const [respiratoryRate, setRespiratoryRate] = useState(18)

  return (
    <Layout>
      <SEO title="Calculator" />
      <Container>
        <Header title="Calculator">
          <Logo>
            <img src={LogoIcon} />
          </Logo>
        </Header>
        <Calculator>
          <h1 className="result">
            Index is{" "}
            {Math.round(
              ((SpO2 * 0.01) / (FiO2 * 0.01) / respiratoryRate) * 10000
            ) / 100}
          </h1>
          <label htmlFor="Sp02">
            SpO<sub>2</sub>
          </label>
          <input
            type="number"
            value={SpO2}
            name="SpO2"
            onChange={e => setSpO2(e.target.value)}
          ></input>
          <label htmlFor="FiO2">
            FiO<sub>2</sub>
          </label>
          <input
            type="number"
            value={FiO2}
            name="FiO2"
            onChange={e => setFiO2(e.target.value)}
          ></input>
          <label htmlFor="respRate">Respiratory Rate</label>
          <input
            type="number"
            value={respiratoryRate}
            name="respRate"
            onChange={e => setRespiratoryRate(e.target.value)}
          ></input>
        </Calculator>
      </Container>
    </Layout>
  )
}

export default IndexPage
