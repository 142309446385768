import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import * as v from "../variables"

const Container = styled.li`
  a {
    ${v.heading.c}
    display: block;
    padding: ${v.baseline(1.5)} ${v.baseline(2)};
    text-decoration: none;
    border-top: 1px solid rgb(0 0 0 / 0.2);
    color: black;
    transition: background-color ${v.speed.fast};
    position: relative;
    background-color: white;

    &:after {
      content: "›";
      ${v.heading.b};
      position: absolute;
      right: ${v.baseline(2)};
      top: 9px;
      color: ${v.purple};
    }

    &:visited {
      background-color: #fdfaff;
    }
    &:hover {
      background: #f2f2f2;
    }
  }
`

const NavListItem = ({ link, label }) => (
  <Container>
    <Link to={link}>{label}</Link>
  </Container>
)

export default NavListItem
