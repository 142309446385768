import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import * as v from "../variables"
import LogoImage from "../../images/am-icon.png"

const Container = styled.ul`
  grid-column: main;
  display: flexbox;
  align-items: center;

  z-index: 1;

  li {
    &:before {
      content: "›";
      margin: 0 ${v.baseline(0.5)};
    }

    &:first-of-type:before {
      display: none;
    }

    ${v.text.b};
    color: rgb(0 0 0 /0.6);
    a {
      text-decoration: none;
      color: ${v.purple};
      transition: color ${v.speed.fast};

      &:hover {
        text-decoration: underline;
        color: ${v.darkPurple};
      }
    }
  }
`

const Logo = styled.img`
  width: ${v.baseline(3)};
  margin-right: ${v.baseline()};
`

// TODO: Ensure later-level breadcrumbs have correct link (need to prepend slugs of previous breadcrumbs)

const Breadcrumb = ({ content, pageTitle }) => {
  console.log("Crumbs", content)
  return (
    <Container>
      <Logo src={LogoImage}></Logo>
      <li>
        <Link to="/">Home</Link>
      </li>

      {content?.map(element => (
        <li key={element.slug}>
          <Link to={`/` + element.slug}>{element.crumb || element.label}</Link>
        </li>
      ))}
      <li>{pageTitle}</li>
    </Container>
  )
}

export default Breadcrumb
